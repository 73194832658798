import React from 'react'
import moment from 'moment'
import LazyImage from '../imageLazy'

export default props => {
    const { article } = props
    return(
        <div onClick={props.changeRoute} className="pbItem pbItem-comments pb-avatar">
            <div className="preview">
                <div className="preview__overlay"></div>
                <LazyImage
                    className="preview__img"
                    image={{src: article.img}}
                />
                {/*<img className="preview__img" src={article.img} alt="" />*/}
            </div>
            <div className="content">
                <p className="author">{article.author}</p>
                <h2 className="caption">{article.title}</h2>
                <p className="text">{article.title}</p>
                <p className="date">{moment(article.publishDate).format('DD.MM.YYYY HH:mm')} • {article.author}</p>
            </div>
        </div>
    )
}