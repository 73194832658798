import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Title from '../reuseComponents/Title'
import ArticleFoot from './ArticleFooter'
import { withRouter } from 'react-router'
import axios from 'axios'
import {ALL_PUBLISH_RU, ALL_PUBLISH_UA, url,POLITIC_ID} from "../../../constants"


class FooterListCategories extends PureComponent{

    state = {
        articles: []
    }

    componentDidMount(){
        this.handleGetArticle()
    }

    handleGetArticle(){
        const  category  = this.props.categories.find(item => item.id === POLITIC_ID)
        const { locale } = this.props
        axios.get(`${url}/api/main/articles`,{
            params: {
                locale: locale,
                limit: 4,
                category: category ? category.url : null,
            }
        })
            .then(res=>{
                this.setState({
                    articles: res.data.articles
                })
            })
            .catch(err=> console.log(err))

    }

    render(){
        const { articles } = this.state
        const  category  = this.props.categories.find(item => item.id === POLITIC_ID)
        const { locale } = this.props.match.params
        const CHOSE_RED  = locale === 'ru' ? 'Выбор редакции' : 'Вибір редакції'
        return(
            <div className="best">
                <div className="wrapper">
                    <Title title={CHOSE_RED} link={`/${category ? category.url : 'all'}`} rightLabel={locale === 'ru' ? ALL_PUBLISH_RU+' рубрики' : ALL_PUBLISH_UA+' рубрики'}/>
                    <div className="slider items" id="sliderFoot">
                        {articles.map( (item,ind) => <ArticleFoot article={item} key={item.id} />)}
                    </div>
                </div>
            </div>
        )
    }
}

FooterListCategories.defaultProps = {
    categories: []
}

const mapStateToProps = state => {
    return {
        categories: state.siteReducer.categories
    }
}

export default withRouter(connect(mapStateToProps)(FooterListCategories))

