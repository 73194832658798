import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Title from '../reuseComponents/Title'
import {url,FACEBOOK_ID,DIGEST} from "../../../constants";
import axios from 'axios'
import Article from "./articleItemFooter";
import history from '../../../history'
import { withRouter } from 'react-router'

class GreyBlock extends PureComponent {

    constructor(props){
        super(props)
        this.handleChangeRoute = this.handleChangeRoute.bind(this)
        this.state = {
            articles: []
        }
    }



    handleChangeRoute (article) {
        const { locale } = this.props.match.params
        const category = this.props.categories.find(item => item.id === DIGEST)
        history.push(`/${locale}/${category ? category.url : 'no-category'}/${article.url}`)
    }


    componentDidMount() {
        this.handleGetArticle()
    }

    handleGetArticle(){
        const { locale } = this.props.match.params
        const category = this.props.categories.find(item => item.id === DIGEST)
        axios.get(`${url}/api/main/articles`,{
            params: {
                limit: 3,
                category: category ? category.url : null,
                locale: locale
            }
        })
            .then(res=> {
                this.setState({
                    articles: res.data.articles
                })
            })
            .catch(err => console.log(err))
    }

    render(){
        const { articles } = this.state
        const category = this.props.categories.find(item => item.id === DIGEST)
        const { locale } = this.props.match.params
        return (
            <div className="network">
                <div className="wrapper">
                    <Title  title={category ? category.name : ''} link={`/${category ? category.url : 'all'}`} rightLabel={locale === 'ru' ? 'все публикации рубрики' : 'всі публікації рубрики'} />
                    <div className="items">
                        {articles.map((item,ind)=><Article changeRoute={()=>this.handleChangeRoute(item)} article={item} key={item.id}/>)}
                    </div>
                </div>
            </div>
        )
    }
}

GreyBlock.defaultProps = {
    categories: []
}

const mapStateToProps = state => {
    return {
        categories: state.siteReducer.categories
    }
}

export default withRouter(connect(mapStateToProps)(GreyBlock))