import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router'
import history from '../../../history'
import LazyImage from '../imageLazy'
import IconPlay from "../../../images/icons/icon-play.png"
import IconPic from "../../../images/icons/icon-image.png"

export default withRouter(props => {
    const { article } = props
    const { locale } = props.match.params
    return (
        <div onClick={()=>handleClick(locale,article.category,article.url)} className="pbItem pbItem-best">
            <div className="preview">
                <div className="content-type">
                    <div className="btn">
                        {article.isPhoto ? <img src={`${IconPic}`} alt=""/> : null}
                        {article.isVideo ? <img src={`${IconPlay}`} alt=""/> : null}
                    </div>
                </div>
                <div className="preview__overlay"></div>
                <LazyImage
                    className="preview__img"
                    image={{src: article.img}}
                />
                {/*<img className="preview__img" src={article.img} alt="" />*/}
            </div>
            <div className="content">
                <div className="label">Новость часа</div>
                <input type="button" className="btn" value={`${article.category.length > 0 ? article.category[0].name : 'Без категории'}`} />
                <h2 className="caption">{article.title}</h2>
                <p className="text">{article.shortDescription}</p>
                <p className="date">{moment(article.publishDate).format('DD.MM.YYYY, HH:mm')} • {article.author}</p>
            </div>
        </div>
    )
})

const handleClick = (locale,category,article) => {
    let catLink = 'no-category'
    if(category.length > 0){
         catLink = category[0].url
    }
    history.push(`/${locale}/${catLink}/${article}`)
}