import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import LogoBlue from '../../../images/logo_MIR-b.png'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import axios from "axios";
import {url,EMAIL_RED_RU,EMAIL_RED_UA,RULES_SITE_RU,RULES_SITE_UA,SECURE_RIGHTS_RU,SECURE_RIGHTS_UA} from "../../../constants";
import {bindActionCreators} from "redux";
import {getAllCategoriesSite} from "../../../ac";
import moment from 'moment'

class NavFoot extends PureComponent{

    componentDidMount(){
        const { locale } = this.props.match.params
        const { categories } = this.props
        if(categories.length === 0)
        axios.get(`${url}/api/main/categories`,{
            params: {
                lang: locale
            }
        })
            .then(res=>{
                let categories = res
                    .data
                    .sort((a,b) => parseInt(a.sortOrder) > parseInt(b.sortOrder) ? 1 : -1)
                this.props.getAllCategories(categories)
            })
            .catch(err=>console.log(err))
    }

    render(){
        const { locale } = this.props.match.params
        return(
            <div className="nav-bottom">
                <div className="wrapper">
                    <div className="logo">
                        <img src={LogoBlue} alt="" />
                            <p>© 2014-{moment().format('YYYY')}. {locale === 'ru' ? SECURE_RIGHTS_RU : SECURE_RIGHTS_UA}.</p>
                    </div>
                    <div className="nav">
                        <ul>
                            {getListCategories(locale,this.props.categories.slice(0,6))}
                        </ul>
                    </div>
                    <div className="contacts">
                        <a className="email-text" href="mailto:someone@example.com?Subject=Hello%20again" target="_top">{locale === 'ru' ? EMAIL_RED_RU : EMAIL_RED_UA}</a>
                        <a className="mail-link" href="#">info@iamir.info</a>
                        <Link className="rules" to={`/${locale}/user_contract`}>{locale === 'ru' ? RULES_SITE_RU : RULES_SITE_UA}</Link>
                        <p className="copy">© 2014-{moment().format('YYYY')}. {locale === 'ru' ? SECURE_RIGHTS_RU : SECURE_RIGHTS_UA}.</p>
                    </div>
                </div>
            </div>
        )
    }
}

NavFoot.defaultProps = {
    categories: []
}

const mapDistchToProps = dispatch => {
    return {
        getAllCategories: bindActionCreators(getAllCategoriesSite,dispatch),
    }
}

const getListCategories = (locale,categories) => categories.map(item => <li key={item.id}><Link to={`/${locale}/${item.url}`}>{item.name}</Link></li>)

const mapStateToProps = state => {
    return {
        categories: state.siteReducer.categories
    }

}

export default withRouter(connect(mapStateToProps,mapDistchToProps)(NavFoot))