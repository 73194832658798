import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import './styles/footer.scss'
import CategoriesFoot from './footerComponents/categories'
import WhiteBlock from './footerComponents/whiteBlockFooter'
import GreyBlock from './footerComponents/greyBlockFooter'
import NavFoot from './footerComponents/footerNav'

class FooterSite extends PureComponent {
    render(){
        const { locale } = this.props
        return(
            this.props.categories.length > 0 && <footer key={locale} className="footer">
                <CategoriesFoot locale={locale}/>
                <WhiteBlock locale={locale}/>
                <GreyBlock />
                <NavFoot />
            </footer>
        )
    }
}

FooterSite.defaultProps = {
    categories: []
}

const mapStateToProps = state => {
    return {
        categories: state.siteReducer.categories
    }

}

export default connect(mapStateToProps,null)(FooterSite)