import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import Article from './articleItemFooter'
import Title from '../reuseComponents/Title'
import axios from 'axios'
import {url, THINK_LOUD, ALL_PUBLISH_RU, ALL_PUBLISH_UA} from "../../../constants"
import history from "../../../history"
import { withRouter } from 'react-router'


class WhiteBlock extends PureComponent{

    constructor(props){
        super(props)
        this.handleChangeRoute = this.handleChangeRoute.bind(this)
        this.state = {
            articles: []
        }
    }

    componentDidMount() {
        this.handleGetArticle()
    }

    handleChangeRoute (article) {
        const { locale } = this.props.match.params
        const category = this.props.categories.find(item => item.id === THINK_LOUD)
        history.push(`/${locale}/${category ? category.url : 'no-category'}/${article.url}`)
    }

    handleGetArticle(){
        const { locale } = this.props.match.params
        const category = this.props.categories.find(item => item.id === THINK_LOUD)
        axios.get(`${url}/api/main/articles`,{
            params: {
                limit: 3,
                category: category ? category.url : null,
                locale: locale,
            }
        })
            .then(res=> {
                this.setState({
                    articles: res.data.articles
                })
            })
            .catch(err => console.log(err))
    }

    render(){
        const { articles } = this.state
        const { locale } = this.props
        const category = this.props.categories.find(item => item.id === THINK_LOUD)
        return(
            <div className="comments">
                <div className="wrapper">
                    <Title title={category ? category.name : ''} link={`/${category ? category.url : 'all'}`} rightLabel={locale === 'ru' ? ALL_PUBLISH_RU+' рубрики' : ALL_PUBLISH_UA+' рубрики'}/>
                    <div className="items">
                        {articles.map((item,ind)=><Article changeRoute={()=>this.handleChangeRoute(item)} article={item} key={item.id}/>)}
                    </div>
                </div>
            </div>
        )
    }
}

WhiteBlock.defaultProps = {
    categories: []
}

const mapStateToProps = state => {
    return {
        categories: state.siteReducer.categories
    }
}

export default withRouter(connect(mapStateToProps)(WhiteBlock))